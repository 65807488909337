define("discourse/plugins/discourse-assign/discourse/controllers/user-activity-assigned", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@ember/template", "discourse/controllers/user-topics-list", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse-common/lib/get-url", "discourse-common/lib/icon-library", "discourse-common/utils/decorators", "I18n"], function (_exports, _controller, _object, _service, _template, _userTopicsList, _environment, _debounce, _getUrl, _iconLibrary, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let UserActivityAssigned = _exports.default = (_dec = (0, _decorators.default)("model.topics.length", "search"), (_class = class UserActivityAssigned extends _userTopicsList.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "taskActions", _descriptor, this);
      _initializerDefineProperty(this, "user", _descriptor2, this);
      _defineProperty(this, "queryParams", ["order", "ascending", "search"]);
      _defineProperty(this, "order", "");
      _defineProperty(this, "ascending", false);
      _defineProperty(this, "search", "");
    }
    doesntHaveAssignments(topicsLength, search) {
      return !search && !topicsLength;
    }
    emptyStateBody() {
      return (0, _template.htmlSafe)(_I18n.default.t("user.no_assignments_body", {
        preferencesUrl: (0, _getUrl.default)("/my/preferences/notifications"),
        icon: (0, _iconLibrary.iconHTML)("user-plus")
      }));
    }
    _setSearchTerm(searchTerm) {
      this.set("search", searchTerm);
      this.refreshModel();
    }
    refreshModel() {
      this.set("loading", true);
      this.store.findFiltered("topicList", {
        filter: this.model.filter,
        params: {
          order: this.order,
          ascending: this.ascending,
          search: this.search
        }
      }).then(result => this.set("model", result)).finally(() => {
        this.set("loading", false);
      });
    }
    changeSort(sortBy) {
      if (sortBy === this.order) {
        this.toggleProperty("ascending");
        this.refreshModel();
      } else {
        this.setProperties({
          order: sortBy,
          ascending: false
        });
        this.refreshModel();
      }
    }
    onChangeFilter(value) {
      (0, _debounce.default)(this, this._setSearchTerm, value, _environment.INPUT_DELAY * 2);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "taskActions", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "user", [_controller.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "doesntHaveAssignments", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "doesntHaveAssignments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "emptyStateBody", [_decorators.default], Object.getOwnPropertyDescriptor(_class.prototype, "emptyStateBody"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSort", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeSort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeFilter"), _class.prototype)), _class));
});