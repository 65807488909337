define("discourse/plugins/discourse-assign/discourse/raw-views/assign-topic-buttons", ["exports", "@ember/object", "@ember/service", "discourse/lib/raw-render-glimmer", "discourse/plugins/discourse-assign/discourse/components/assigned-topic-list-column", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _object, _service, _rawRenderGlimmer, _assignedTopicListColumn, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const ASSIGN_LIST_ROUTES = ["userActivity.assigned", "group.assigned.show"];
  let _class = _exports.default = (_class2 = class _class2 extends _object.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    get html() {
      if (ASSIGN_LIST_ROUTES.includes(this.router.currentRouteName)) {
        return (0, _rawRenderGlimmer.default)(this, "td.assign-topic-buttons", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          <AssignedTopicListColumn @topic={{@data.topic}} />
        */
        {
          "id": "DgO3j+2j",
          "block": "[[[8,[32,0],null,[[\"@topic\"],[[30,1,[\"topic\"]]]],null]],[\"@data\"],false,[]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-assign/discourse/raw-views/assign-topic-buttons.js",
          "scope": () => [_assignedTopicListColumn.default],
          "isStrictMode": true
        }), (0, _templateOnly.default)()), {
          topic: this.topic
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2);
});