define("discourse/plugins/discourse-assign/discourse/components/assign-user-form", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/plugins/discourse-assign/discourse/components/assignment", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _assignment, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _AssignUserForm;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AssignUserForm = _exports.default = (_class = (_AssignUserForm = class AssignUserForm extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "showValidationErrors", _descriptor, this);
      this.args.formApi.submit = this.assign;
    }
    get assigneeIsEmpty() {
      return !this.args.model.username && !this.args.model.group_name;
    }
    async assign() {
      if (this.assigneeIsEmpty) {
        this.showValidationErrors = true;
        return;
      }
      await this.args.onSubmit();
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <Assignment
        @assignment={{@model}}
        @onSubmit={{this.assign}}
        @showValidationErrors={{this.showValidationErrors}}
      />
    
  */
  {
    "id": "DEZrvZWn",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@assignment\",\"@onSubmit\",\"@showValidationErrors\"],[[30,1],[30,0,[\"assign\"]],[30,0,[\"showValidationErrors\"]]]],null],[1,\"\\n  \"]],[\"@model\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-assign/discourse/components/assign-user-form.js",
    "scope": () => [_assignment.default],
    "isStrictMode": true
  }), _AssignUserForm), _AssignUserForm), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showValidationErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "assign", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "assign"), _class.prototype)), _class);
});