define("discourse/plugins/discourse-assign/discourse/controllers/group-assigned", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse-common/utils/decorators"], function (_exports, _controller, _object, _service, _ajax, _environment, _debounce, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let GroupAssigned = _exports.default = (_dec = (0, _decorators.default)("router.currentRoute.queryParams.order"), _dec2 = (0, _decorators.default)("router.currentRoute.queryParams.ascending"), _dec3 = (0, _decorators.default)("router.currentRoute.queryParams.search"), _dec4 = (0, _decorators.default)("site.mobileView"), (_class = class GroupAssigned extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "application", _descriptor2, this);
      _defineProperty(this, "loading", false);
      _defineProperty(this, "offset", 0);
      _defineProperty(this, "filterName", "");
      _defineProperty(this, "filter", "");
    }
    order(order) {
      return order || "";
    }
    ascending(ascending) {
      return ascending || false;
    }
    search(search) {
      return search || "";
    }
    isDesktop(mobileView) {
      return !mobileView;
    }
    _setFilter(filter) {
      this.set("loading", true);
      this.set("offset", 0);
      this.set("filter", filter);
      const groupName = this.group.name;
      (0, _ajax.ajax)(`/assign/members/${groupName}`, {
        type: "GET",
        data: {
          filter: this.filter,
          offset: this.offset
        }
      }).then(result => {
        if (this.router.currentRoute.params.filter !== "everyone") {
          this.router.transitionTo("group.assigned.show", groupName, "everyone");
        }
        this.set("members", result.members);
      }).finally(() => {
        this.set("loading", false);
      });
    }
    findMembers(refresh) {
      if (refresh) {
        this.set("members", this.model.members);
        return;
      }
      if (this.loading || !this.model) {
        return;
      }
      if (this.model.members.length >= this.offset + 50) {
        this.set("loading", true);
        this.set("offset", this.offset + 50);
        (0, _ajax.ajax)(`/assign/members/${this.group.name}`, {
          type: "GET",
          data: {
            filter: this.filter,
            offset: this.offset
          }
        }).then(result => {
          this.members.pushObjects(result.members);
        }).finally(() => this.set("loading", false));
      }
    }
    loadMore() {
      this.findMembers();
    }
    onChangeFilterName(value) {
      (0, _debounce.default)(this, this._setFilter, value, _environment.INPUT_DELAY * 2);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "application", [_controller.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "order", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "order"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ascending", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "ascending"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isDesktop", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "isDesktop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMore", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeFilterName", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeFilterName"), _class.prototype)), _class));
});