define("discourse/plugins/discourse-assign/discourse/routes/user-activity-assigned", ["exports", "@ember/service", "discourse/lib/cookie", "discourse/routes/user-topic-list", "I18n"], function (_exports, _service, _cookie, _userTopicList, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let UserActivityAssigned = _exports.default = (_class = class UserActivityAssigned extends _userTopicList.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _defineProperty(this, "templateName", "user-activity-assigned");
      _defineProperty(this, "controllerName", "user-activity-assigned");
      _defineProperty(this, "userActionType", 16);
      _defineProperty(this, "noContentHelpKey", "discourse_assigns.no_assigns");
    }
    beforeModel() {
      if (!this.currentUser) {
        (0, _cookie.default)("destination_url", window.location.href);
        this.router.transitionTo("login");
      }
    }
    model(params) {
      return this.store.findFiltered("topicList", {
        filter: `topics/messages-assigned/${this.modelFor("user").username_lower}`,
        params: {
          exclude_category_ids: [-1],
          order: params.order,
          ascending: params.ascending,
          search: params.search
        }
      });
    }
    titleToken() {
      return _I18n.default.t("discourse_assign.assigned");
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});