define("discourse/plugins/discourse-assign/discourse/components/assigned-topic-list-column", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/plugins/discourse-assign/discourse/components/assign-actions-dropdown", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _assignActionsDropdown, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _AssignedTopicListColumn;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AssignedTopicListColumn = _exports.default = (_class = (_AssignedTopicListColumn = class AssignedTopicListColumn extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "taskActions", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
    }
    async unassign(targetId1) {
      let targetType1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "Topic";
      await this.taskActions.unassign(targetId1, targetType1);
      this.router.refresh();
    }
    reassign(topic1) {
      this.taskActions.showAssignModal(topic1, {
        onSuccess: () => this.router.refresh()
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @topic.assigned_to_user}}
        <AssignActionsDropdown
          @topic={{@topic}}
          @assignee={{@topic.assigned_to_user.username}}
          @unassign={{this.unassign}}
          @reassign={{this.reassign}}
        />
      {{else if @topic.assigned_to_group}}
        <AssignActionsDropdown
          @topic={{@topic}}
          @assignee={{@topic.assigned_to_group.name}}
          @group={{true}}
          @unassign={{this.unassign}}
          @reassign={{this.reassign}}
        />
      {{else}}
        <AssignActionsDropdown @topic={{@topic}} @unassign={{this.unassign}} />
      {{/if}}
    
  */
  {
    "id": "CgiPEHvE",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"assigned_to_user\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@topic\",\"@assignee\",\"@unassign\",\"@reassign\"],[[30,1],[30,1,[\"assigned_to_user\",\"username\"]],[30,0,[\"unassign\"]],[30,0,[\"reassign\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,1,[\"assigned_to_group\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@topic\",\"@assignee\",\"@group\",\"@unassign\",\"@reassign\"],[[30,1],[30,1,[\"assigned_to_group\",\"name\"]],true,[30,0,[\"unassign\"]],[30,0,[\"reassign\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[32,0],null,[[\"@topic\",\"@unassign\"],[[30,1],[30,0,[\"unassign\"]]]],null],[1,\"\\n    \"]],[]]]],[]]],[1,\"  \"]],[\"@topic\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-assign/discourse/components/assigned-topic-list-column.js",
    "scope": () => [_assignActionsDropdown.default],
    "isStrictMode": true
  }), _AssignedTopicListColumn), _AssignedTopicListColumn), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "taskActions", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "unassign", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unassign"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reassign", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reassign"), _class.prototype)), _class);
});