define("discourse/plugins/discourse-assign/discourse/components/bulk-actions/assign-user", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember-compat/tracked-built-ins", "@ember/template-factory"], function (_exports, _component, _component2, _object, _trackedBuiltIns, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
    <AssignUserForm
      @model={{this.model}}
      @onSubmit={{this.assign}}
      @formApi={{this.formApi}}
    />
  </div>
  
  <div>
    <DButton
      class="btn-primary"
      @action={{this.formApi.submit}}
      @label={{if
        this.model.reassign
        "discourse_assign.reassign.title"
        "discourse_assign.assign_modal.assign"
      }}
    />
  </div>
  */
  {
    "id": "1BdIAbQx",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@model\",\"@onSubmit\",\"@formApi\"],[[30,0,[\"model\"]],[30,0,[\"assign\"]],[30,0,[\"formApi\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,0,[\"formApi\",\"submit\"]],[52,[30,0,[\"model\",\"reassign\"]],\"discourse_assign.reassign.title\",\"discourse_assign.assign_modal.assign\"]]],null],[1,\"\\n\"],[13]],[],false,[\"assign-user-form\",\"d-button\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-assign/discourse/components/bulk-actions/assign-user.hbs",
    "isStrictMode": false
  });
  let AssignUser = _exports.default = (_class = class AssignUser extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "model", new _trackedBuiltIns.TrackedObject({}));
      // `submit` property will be mutated by the `AssignUserForm` component
      _defineProperty(this, "formApi", {
        submit() {}
      });
    }
    async assign() {
      return this.args.performAndRefresh({
        type: "assign",
        username: this.model.username,
        status: this.model.status,
        note: this.model.note
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "assign", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "assign"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AssignUser);
});