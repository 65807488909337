define("discourse/plugins/discourse-assign/discourse/components/assigned-to-post", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/components/dropdown-menu", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "float-kit/components/d-menu", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _dropdownMenu, _dIcon, _i18n, _dMenu, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _AssignedToPost;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AssignedToPost = _exports.default = (_class = (_AssignedToPost = class AssignedToPost extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "taskActions", _descriptor, this);
    }
    unassign() {
      this.taskActions.unassignPost(this.args.post);
    }
    editAssignment() {
      this.taskActions.showAssignPostModal(this.args.post);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @assignedToUser}}
        {{icon "user-plus"}}
      {{else}}
        {{icon "group-plus"}}
      {{/if}}
  
      <span class="assign-text">
        {{i18n "discourse_assign.assigned_to"}}
      </span>
  
      <a href={{@href}} class="assigned-to-username">
        {{#if @assignedToUser}}
          {{@assignedToUser.username}}
        {{else}}
          {{@assignedToGroup.name}}
        {{/if}}
      </a>
  
      <DMenu
        @identifier="post-assign-menu"
        @icon="ellipsis-h"
        class="btn-flat more-button"
        @autofocus={{true}}
      >
        <DropdownMenu as |dropdown|>
          <dropdown.item>
            <DButton
              @action={{this.unassign}}
              @icon="user-plus"
              @label="discourse_assign.unassign.title"
              class="btn-transparent unassign-btn"
            />
          </dropdown.item>
          <dropdown.item>
            <DButton
              @action={{this.editAssignment}}
              @icon="group-plus"
              @label="discourse_assign.reassign.title_w_ellipsis"
              class="btn-transparent edit-assignment-btn"
            />
          </dropdown.item>
        </DropdownMenu>
      </DMenu>
    
  */
  {
    "id": "A42AgQci",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[1,[28,[32,0],[\"user-plus\"],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[32,0],[\"group-plus\"],null]],[1,\"\\n\"]],[]]],[1,\"\\n    \"],[10,1],[14,0,\"assign-text\"],[12],[1,\"\\n      \"],[1,[28,[32,1],[\"discourse_assign.assigned_to\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,3],[15,6,[30,2]],[14,0,\"assigned-to-username\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[1,[30,1,[\"username\"]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,3,[\"name\"]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[8,[32,2],[[24,0,\"btn-flat more-button\"]],[[\"@identifier\",\"@icon\",\"@autofocus\"],[\"post-assign-menu\",\"ellipsis-h\",true]],[[\"default\"],[[[[1,\"\\n      \"],[8,[32,3],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,4,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[32,4],[[24,0,\"btn-transparent unassign-btn\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"unassign\"]],\"user-plus\",\"discourse_assign.unassign.title\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n        \"],[8,[30,4,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[32,4],[[24,0,\"btn-transparent edit-assignment-btn\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"editAssignment\"]],\"group-plus\",\"discourse_assign.reassign.title_w_ellipsis\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[4]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@assignedToUser\",\"@href\",\"@assignedToGroup\",\"dropdown\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-assign/discourse/components/assigned-to-post.js",
    "scope": () => [_dIcon.default, _i18n.default, _dMenu.default, _dropdownMenu.default, _dButton.default],
    "isStrictMode": true
  }), _AssignedToPost), _AssignedToPost), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "taskActions", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "unassign", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unassign"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editAssignment", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editAssignment"), _class.prototype)), _class);
});