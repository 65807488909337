define("discourse/plugins/discourse-assign/discourse/components/user-menu/assigns-list", ["exports", "@ember/object", "@ember/object/computed", "discourse/components/user-menu/notifications-list", "I18n", "discourse/plugins/discourse-assign/discourse/components/user-menu/assigns-list-empty-state"], function (_exports, _object, _computed, _notificationsList, _I18n, _assignsListEmptyState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  class UserMenuAssignNotificationsList extends _notificationsList.default {
    get dismissTypes() {
      return this.filterByTypes;
    }
    get showDismiss() {
      return this._unreadAssignedNotificationsCount > 0;
    }
    get dismissTitle() {
      return _I18n.default.t("user.dismiss_assigned_tooltip");
    }
    get showAllHref() {
      return `${this.currentUser.path}/activity/assigned`;
    }
    get showAllTitle() {
      return _I18n.default.t("user_menu.view_all_assigned");
    }
    get itemsCacheKey() {
      return "user-menu-assigns-tab";
    }
    get emptyStateComponent() {
      return _assignsListEmptyState.default;
    }
    get alwaysRenderDismissConfirmation() {
      return true;
    }
    get _unreadAssignedNotificationsCount() {
      const key = `grouped_unread_notifications.${this.site.notification_types.assigned}`;
      // we're retrieving the value with get() so that Ember tracks the property
      // and re-renders the UI when it changes.
      // we can stop using `get()` when the User model is refactored into native
      // class with @tracked properties.
      return this.currentUser.get(key) || 0;
    }
    get dismissConfirmationText() {
      return _I18n.default.t("notifications.dismiss_confirmation.body.assigns", {
        count: this._unreadAssignedNotificationsCount
      });
    }
    async fetchItems() {
      return new SortedItems(await super.fetchItems()).sortedItems;
    }
  }
  _exports.default = UserMenuAssignNotificationsList;
  let SortedItems = (_dec = (0, _computed.sort)("items", "itemsSorting"), (_class = class SortedItems {
    constructor(items) {
      _defineProperty(this, "itemsSorting", ["notification.read", "notification.data.message:desc", "notification.created_at:desc"]);
      _initializerDefineProperty(this, "sortedItems", _descriptor, this);
      (0, _object.set)(this, "items", items);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sortedItems", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});